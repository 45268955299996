import React from "react"
import styled from "styled-components"
import { mediaquery } from '../variable';
import { Link, graphql } from "gatsby"

import Toc from "../components/toc"
// import Rank from "../components/rank"
// import Bio from "../components/bio"
import TwitterTimeLine from "../components/twitter-timeline"
import GithubContributionsGraph from "../components/github-contributions-graph"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mediaquery.desktopLg`
    margin-left: 30px;
  `}
`;

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article
        className="blog-post custom-blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header className="custom-title-cover">
          <h1 className="custom-title" itemProp="headline">{post.frontmatter.title}</h1>
          <div className="custom-date-cover">
            <p className="custom-date">created_at:{post.frontmatter.date}</p>
            <p className="custom-date">updated_at:{post.frontmatter.updated_at}</p>
          </div>
          <Toc data={post.tableOfContents} />
        </header>
        <section
          className="custom-contents-cover"
          dangerouslySetInnerHTML={{ __html: post.html }}
          itemProp="articleBody"
        />
      </article>
      <Sidebar>
        <TwitterTimeLine />
        <GithubContributionsGraph />
      </Sidebar>
      {/* <Rank /> */}

      {/* <nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav> */}
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      tableOfContents(
        absolute: false
        pathToSlugField: "frontmatter.path"
        maxDepth: 3
      )
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        updated_at(formatString: "MMMM DD, YYYY")
        title
        description
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
